[class^='col'] {
  margin-bottom: 0.5em; }

@media only screen and (min-width: 80rem) {
  .app .grid.fixed-center {
    max-width: 1200px;
    margin: auto; } }

.reverse .col-xs4-offset-0 {
  margin-left: 0;
  margin-right: 0%; }

.reverse .col-xs4-offset-1 {
  margin-left: 0;
  margin-right: 25%; }

.reverse .col-xs4-offset-2 {
  margin-left: 0;
  margin-right: 50%; }

.reverse .col-xs4-offset-3 {
  margin-left: 0;
  margin-right: 75%; }

.reverse .col-xs8-offset-0 {
  margin-left: 0;
  margin-right: 0%; }

.reverse .col-xs8-offset-1 {
  margin-left: 0;
  margin-right: 12.5%; }

.reverse .col-xs8-offset-2 {
  margin-left: 0;
  margin-right: 25%; }

.reverse .col-xs8-offset-3 {
  margin-left: 0;
  margin-right: 37.5%; }

.reverse .col-xs8-offset-4 {
  margin-left: 0;
  margin-right: 50%; }

.reverse .col-xs8-offset-5 {
  margin-left: 0;
  margin-right: 62.5%; }

.reverse .col-xs8-offset-6 {
  margin-left: 0;
  margin-right: 75%; }

.reverse .col-xs8-offset-7 {
  margin-left: 0;
  margin-right: 87.5%; }

.reverse .col-xs-offset-0 {
  margin-left: 0;
  margin-right: 0%; }

.reverse .col-xs-offset-1 {
  margin-left: 0;
  margin-right: 12.5%; }

.reverse .col-xs-offset-2 {
  margin-left: 0;
  margin-right: 25%; }

.reverse .col-xs-offset-3 {
  margin-left: 0;
  margin-right: 37.5%; }

.reverse .col-xs-offset-4 {
  margin-left: 0;
  margin-right: 50%; }

.reverse .col-xs-offset-5 {
  margin-left: 0;
  margin-right: 62.5%; }

.reverse .col-xs-offset-6 {
  margin-left: 0;
  margin-right: 75%; }

.reverse .col-xs-offset-7 {
  margin-left: 0;
  margin-right: 87.5%; }

@media only screen and (min-width: 37.5rem) {
  .reverse .col-sm8-offset-0 {
    margin-left: 0;
    margin-right: 0%; }
  .reverse .col-sm8-offset-1 {
    margin-left: 0;
    margin-right: 12.5%; }
  .reverse .col-sm8-offset-2 {
    margin-left: 0;
    margin-right: 25%; }
  .reverse .col-sm8-offset-3 {
    margin-left: 0;
    margin-right: 37.5%; }
  .reverse .col-sm8-offset-4 {
    margin-left: 0;
    margin-right: 50%; }
  .reverse .col-sm8-offset-5 {
    margin-left: 0;
    margin-right: 62.5%; }
  .reverse .col-sm8-offset-6 {
    margin-left: 0;
    margin-right: 75%; }
  .reverse .col-sm8-offset-7 {
    margin-left: 0;
    margin-right: 87.5%; }
  .reverse .col-sm12-offset-0 {
    margin-left: 0;
    margin-right: 0%; }
  .reverse .col-sm12-offset-1 {
    margin-left: 0;
    margin-right: 8.33333%; }
  .reverse .col-sm12-offset-2 {
    margin-left: 0;
    margin-right: 16.66667%; }
  .reverse .col-sm12-offset-3 {
    margin-left: 0;
    margin-right: 25%; }
  .reverse .col-sm12-offset-4 {
    margin-left: 0;
    margin-right: 33.33333%; }
  .reverse .col-sm12-offset-5 {
    margin-left: 0;
    margin-right: 41.66667%; }
  .reverse .col-sm12-offset-6 {
    margin-left: 0;
    margin-right: 50%; }
  .reverse .col-sm12-offset-7 {
    margin-left: 0;
    margin-right: 58.33333%; }
  .reverse .col-sm12-offset-8 {
    margin-left: 0;
    margin-right: 66.66667%; }
  .reverse .col-sm12-offset-9 {
    margin-left: 0;
    margin-right: 75%; }
  .reverse .col-sm12-offset-10 {
    margin-left: 0;
    margin-right: 83.33333%; }
  .reverse .col-sm12-offset-11 {
    margin-left: 0;
    margin-right: 91.66667%; }
  .reverse .col-sm-offset-0 {
    margin-left: 0;
    margin-right: 0%; }
  .reverse .col-sm-offset-1 {
    margin-left: 0;
    margin-right: 8.33333%; }
  .reverse .col-sm-offset-2 {
    margin-left: 0;
    margin-right: 16.66667%; }
  .reverse .col-sm-offset-3 {
    margin-left: 0;
    margin-right: 25%; }
  .reverse .col-sm-offset-4 {
    margin-left: 0;
    margin-right: 33.33333%; }
  .reverse .col-sm-offset-5 {
    margin-left: 0;
    margin-right: 41.66667%; }
  .reverse .col-sm-offset-6 {
    margin-left: 0;
    margin-right: 50%; }
  .reverse .col-sm-offset-7 {
    margin-left: 0;
    margin-right: 58.33333%; }
  .reverse .col-sm-offset-8 {
    margin-left: 0;
    margin-right: 66.66667%; }
  .reverse .col-sm-offset-9 {
    margin-left: 0;
    margin-right: 75%; }
  .reverse .col-sm-offset-10 {
    margin-left: 0;
    margin-right: 83.33333%; }
  .reverse .col-sm-offset-11 {
    margin-left: 0;
    margin-right: 91.66667%; } }

@media only screen and (min-width: 60rem) {
  .reverse .col-md12-offset-0 {
    margin-left: 0;
    margin-right: 0%; }
  .reverse .col-md12-offset-1 {
    margin-left: 0;
    margin-right: 8.33333%; }
  .reverse .col-md12-offset-2 {
    margin-left: 0;
    margin-right: 16.66667%; }
  .reverse .col-md12-offset-3 {
    margin-left: 0;
    margin-right: 25%; }
  .reverse .col-md12-offset-4 {
    margin-left: 0;
    margin-right: 33.33333%; }
  .reverse .col-md12-offset-5 {
    margin-left: 0;
    margin-right: 41.66667%; }
  .reverse .col-md12-offset-6 {
    margin-left: 0;
    margin-right: 50%; }
  .reverse .col-md12-offset-7 {
    margin-left: 0;
    margin-right: 58.33333%; }
  .reverse .col-md12-offset-8 {
    margin-left: 0;
    margin-right: 66.66667%; }
  .reverse .col-md12-offset-9 {
    margin-left: 0;
    margin-right: 75%; }
  .reverse .col-md12-offset-10 {
    margin-left: 0;
    margin-right: 83.33333%; }
  .reverse .col-md12-offset-11 {
    margin-left: 0;
    margin-right: 91.66667%; }
  .reverse .col-md-offset-0 {
    margin-left: 0;
    margin-right: 0%; }
  .reverse .col-md-offset-1 {
    margin-left: 0;
    margin-right: 8.33333%; }
  .reverse .col-md-offset-2 {
    margin-left: 0;
    margin-right: 16.66667%; }
  .reverse .col-md-offset-3 {
    margin-left: 0;
    margin-right: 25%; }
  .reverse .col-md-offset-4 {
    margin-left: 0;
    margin-right: 33.33333%; }
  .reverse .col-md-offset-5 {
    margin-left: 0;
    margin-right: 41.66667%; }
  .reverse .col-md-offset-6 {
    margin-left: 0;
    margin-right: 50%; }
  .reverse .col-md-offset-7 {
    margin-left: 0;
    margin-right: 58.33333%; }
  .reverse .col-md-offset-8 {
    margin-left: 0;
    margin-right: 66.66667%; }
  .reverse .col-md-offset-9 {
    margin-left: 0;
    margin-right: 75%; }
  .reverse .col-md-offset-10 {
    margin-left: 0;
    margin-right: 83.33333%; }
  .reverse .col-md-offset-11 {
    margin-left: 0;
    margin-right: 91.66667%; } }

@media only screen and (min-width: 80rem) {
  .reverse .col-lg12-offset-0 {
    margin-left: 0;
    margin-right: 0%; }
  .reverse .col-lg12-offset-1 {
    margin-left: 0;
    margin-right: 8.33333%; }
  .reverse .col-lg12-offset-2 {
    margin-left: 0;
    margin-right: 16.66667%; }
  .reverse .col-lg12-offset-3 {
    margin-left: 0;
    margin-right: 25%; }
  .reverse .col-lg12-offset-4 {
    margin-left: 0;
    margin-right: 33.33333%; }
  .reverse .col-lg12-offset-5 {
    margin-left: 0;
    margin-right: 41.66667%; }
  .reverse .col-lg12-offset-6 {
    margin-left: 0;
    margin-right: 50%; }
  .reverse .col-lg12-offset-7 {
    margin-left: 0;
    margin-right: 58.33333%; }
  .reverse .col-lg12-offset-8 {
    margin-left: 0;
    margin-right: 66.66667%; }
  .reverse .col-lg12-offset-9 {
    margin-left: 0;
    margin-right: 75%; }
  .reverse .col-lg12-offset-10 {
    margin-left: 0;
    margin-right: 83.33333%; }
  .reverse .col-lg12-offset-11 {
    margin-left: 0;
    margin-right: 91.66667%; }
  .reverse .col-lg-offset-0 {
    margin-left: 0;
    margin-right: 0%; }
  .reverse .col-lg-offset-1 {
    margin-left: 0;
    margin-right: 8.33333%; }
  .reverse .col-lg-offset-2 {
    margin-left: 0;
    margin-right: 16.66667%; }
  .reverse .col-lg-offset-3 {
    margin-left: 0;
    margin-right: 25%; }
  .reverse .col-lg-offset-4 {
    margin-left: 0;
    margin-right: 33.33333%; }
  .reverse .col-lg-offset-5 {
    margin-left: 0;
    margin-right: 41.66667%; }
  .reverse .col-lg-offset-6 {
    margin-left: 0;
    margin-right: 50%; }
  .reverse .col-lg-offset-7 {
    margin-left: 0;
    margin-right: 58.33333%; }
  .reverse .col-lg-offset-8 {
    margin-left: 0;
    margin-right: 66.66667%; }
  .reverse .col-lg-offset-9 {
    margin-left: 0;
    margin-right: 75%; }
  .reverse .col-lg-offset-10 {
    margin-left: 0;
    margin-right: 83.33333%; }
  .reverse .col-lg-offset-11 {
    margin-left: 0;
    margin-right: 91.66667%; } }

@media only screen and (min-width: 120rem) {
  .reverse .col-xl12-offset-0 {
    margin-left: 0;
    margin-right: 0%; }
  .reverse .col-xl12-offset-1 {
    margin-left: 0;
    margin-right: 8.33333%; }
  .reverse .col-xl12-offset-2 {
    margin-left: 0;
    margin-right: 16.66667%; }
  .reverse .col-xl12-offset-3 {
    margin-left: 0;
    margin-right: 25%; }
  .reverse .col-xl12-offset-4 {
    margin-left: 0;
    margin-right: 33.33333%; }
  .reverse .col-xl12-offset-5 {
    margin-left: 0;
    margin-right: 41.66667%; }
  .reverse .col-xl12-offset-6 {
    margin-left: 0;
    margin-right: 50%; }
  .reverse .col-xl12-offset-7 {
    margin-left: 0;
    margin-right: 58.33333%; }
  .reverse .col-xl12-offset-8 {
    margin-left: 0;
    margin-right: 66.66667%; }
  .reverse .col-xl12-offset-9 {
    margin-left: 0;
    margin-right: 75%; }
  .reverse .col-xl12-offset-10 {
    margin-left: 0;
    margin-right: 83.33333%; }
  .reverse .col-xl12-offset-11 {
    margin-left: 0;
    margin-right: 91.66667%; }
  .reverse .col-xl-offset-0 {
    margin-left: 0;
    margin-right: 0%; }
  .reverse .col-xl-offset-1 {
    margin-left: 0;
    margin-right: 8.33333%; }
  .reverse .col-xl-offset-2 {
    margin-left: 0;
    margin-right: 16.66667%; }
  .reverse .col-xl-offset-3 {
    margin-left: 0;
    margin-right: 25%; }
  .reverse .col-xl-offset-4 {
    margin-left: 0;
    margin-right: 33.33333%; }
  .reverse .col-xl-offset-5 {
    margin-left: 0;
    margin-right: 41.66667%; }
  .reverse .col-xl-offset-6 {
    margin-left: 0;
    margin-right: 50%; }
  .reverse .col-xl-offset-7 {
    margin-left: 0;
    margin-right: 58.33333%; }
  .reverse .col-xl-offset-8 {
    margin-left: 0;
    margin-right: 66.66667%; }
  .reverse .col-xl-offset-9 {
    margin-left: 0;
    margin-right: 75%; }
  .reverse .col-xl-offset-10 {
    margin-left: 0;
    margin-right: 83.33333%; }
  .reverse .col-xl-offset-11 {
    margin-left: 0;
    margin-right: 91.66667%; } }

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

a, a:active, a:visited, a:hover {
  color: inherit;
  text-decoration: none;
  cursor: pointer; }

*, *::before, *::after {
  box-sizing: border-box; }

svg.icon {
  font-size: inherit;
  vertical-align: bottom; }

body {
  font-family: Lato, sans-serif;
  color: #f5edd3; }

a[target]:hover {
  text-decoration: underline; }

h1, h2, h3, h4, h5, h6 {
  padding: 0.15em;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  text-align: center; }

p {
  padding: 0 0.5em;
  margin: 0.5em 0;
  font-family: 'Noto Serif KR', serif;
  line-height: 1.2em;
  text-align: center;
  text-transform: none;
  color: #cc9389; }
  p a, p a:active, p a:visited, p a:hover {
    color: #f5edd3; }
  p a:hover {
    text-decoration: underline; }

i {
  font-family: Allura, cursive;
  text-transform: none;
  letter-spacing: 0;
  font-size: 1.2em;
  display: inline-block;
  color: #f5edd3; }

b {
  font-style: italic;
  color: #f5edd3; }

h1 {
  font-size: 2rem; }
  h1 > .header-height {
    height: 3rem; }

h2 {
  font-size: 1.7rem; }
  h2 > .header-height {
    height: 2.55rem; }

h3 {
  font-size: 1.5rem; }
  h3 > .header-height {
    height: 2.25rem; }

h4 {
  font-size: 1.3rem; }
  h4 > .header-height {
    height: 1.95rem; }

h5 {
  font-size: 1.1rem; }
  h5 > .header-height {
    height: 1.65rem; }

h6 {
  font-size: 0.9rem; }
  h6 > .header-height {
    height: 1.35rem; }

@media only screen and (min-width: 37.5rem) {
  h1 {
    font-size: 2.6rem; }
    h1 > .header-height {
      height: 3.9rem; }
  h2 {
    font-size: 2.21rem; }
    h2 > .header-height {
      height: 3.315rem; }
  h3 {
    font-size: 1.95rem; }
    h3 > .header-height {
      height: 2.925rem; }
  h4 {
    font-size: 1.69rem; }
    h4 > .header-height {
      height: 2.535rem; }
  h5 {
    font-size: 1.43rem; }
    h5 > .header-height {
      height: 2.145rem; }
  h6 {
    font-size: 1.17rem; }
    h6 > .header-height {
      height: 1.755rem; }
  p {
    font-size: 1rem; } }

@media only screen and (min-width: 60rem) {
  h1 {
    font-size: 3rem; }
    h1 > .header-height {
      height: 4.5rem; }
  h2 {
    font-size: 2.55rem; }
    h2 > .header-height {
      height: 3.825rem; }
  h3 {
    font-size: 2.25rem; }
    h3 > .header-height {
      height: 3.375rem; }
  h4 {
    font-size: 1.95rem; }
    h4 > .header-height {
      height: 2.925rem; }
  h5 {
    font-size: 1.65rem; }
    h5 > .header-height {
      height: 2.475rem; }
  h6 {
    font-size: 1.35rem; }
    h6 > .header-height {
      height: 2.025rem; }
  p {
    font-size: 1.1rem; } }

@media only screen and (min-width: 80rem) {
  h1 {
    font-size: 3.4rem; }
    h1 > .header-height {
      height: 5.1rem; }
  h2 {
    font-size: 2.89rem; }
    h2 > .header-height {
      height: 4.335rem; }
  h3 {
    font-size: 2.55rem; }
    h3 > .header-height {
      height: 3.825rem; }
  h4 {
    font-size: 2.21rem; }
    h4 > .header-height {
      height: 3.315rem; }
  h5 {
    font-size: 1.87rem; }
    h5 > .header-height {
      height: 2.805rem; }
  h6 {
    font-size: 1.53rem; }
    h6 > .header-height {
      height: 2.295rem; }
  p {
    font-size: 1.5rem; }
  p.smaller {
    font-size: 1.2rem; } }

body {
  background-color: #21303d;
  color: #f5edd3; }

:root {
  --browser-address-bar: 0px; }
